import * as React from 'react';
import { Col, Row } from 'antd';
import AppStoreIcon from '../static/images/apply/AppStore.svg';
import PlayStoreIcon from '../static/images/apply/PlayStore.svg';

const { memo } = React;

const AppsLink: React.FC<ILinkComponent> = memo(props => {
	const { logHandler } = props;

	return (
		<Row justify="center" align="middle" gutter={[10, 0]}>
			<Col span={12}>
				<a onClick={() => logHandler('Clicked appstore link', 'ios')}>
					<img
						alt="Get it on the Apple store now!"
						src={AppStoreIcon}
						style={s.img}
						width={195}
						height={65}
					/>
				</a>
			</Col>

			<Col span={12}>
				<a onClick={() => logHandler('Clicked playstore link', 'android')}>
					<img
						alt="Get it on Google Play"
						src={PlayStoreIcon}
						style={s.img}
						width={195}
						height={65}
					/>
				</a>
			</Col>
		</Row>
	);
});

const s: Stylesheet = {
	img: {
		width: '100%',
		height: '100%'
	}
};
export default AppsLink;

import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Col, Row } from 'antd';
import { pushFunnelLog } from '../common/api';
import { isBrowser } from '../common/utils';
import AppsLink from '../components/apps.link';
import Header from '../components/header';
import TelegramLink from '../components/telegram.link';

const { memo, useEffect } = React;

/**
 * Test Cases
 * /?fromwebsite=true&start=102089
 * /?start=102089_XXX
 * /?start=XXX
 * /?id=100000
 */
const IndexPage: React.FC = memo(() => {
	const queryString = isBrowser() ? window.location.search : null;
	const startQueryExists = isBrowser()
		? new URL(location.href).searchParams.get('start')
		: null;
	const jobQuery = isBrowser()
		? queryString.replace(/[^0-9]/g, '') // Strip all characters that isn't a number
		: null;

	/**
	 * We log for each time a user visits this page
	 */
	useEffect(() => {
		const logEntry = async () => await handleLog(`Visited apply.workclass.co`);
		logEntry();
	}, []);

	/**
	 * This is the main handler for where the links go.
	 * @param linkType Link Type that determines where to go
	 */
	const getLink = (linkType: LinkType): string => {
		if (linkType === 'telegram') {
			return startQueryExists
				? `https://t.me/workclasssgbot${queryString}`
				: `https://t.me/workclasssgbot?start=XXX`;
		}

		if (linkType === 'website') {
			if (jobQuery) {
				return `https://workclass.co/auth/register?id=${jobQuery}`;
			} else {
				return `https://workclass.co/auth/register`;
			}
		}

		if (linkType === 'android') {
			return 'https://play.google.com/store/apps/details?id=com.workclass';
		}

		if (linkType === 'ios') {
			return 'https://apps.apple.com/us/app/workclass/id1538103645';
		}
	};

	/**
	 * Log handler
	 * We query cloudflares's api to get the users country, ip and system
	 * @param action
	 */
	const handleLog = async (action: string, linkType?: LinkType) => {
		fetch(`https://www.cloudflare.com/cdn-cgi/trace`)
			.then(res => res.text())
			.then(data => data.split('\n'))
			.then(data =>
				pushFunnelLog(
					action,
					data[8], // Country
					data[2], // IP
					data[5], // System
					queryString
				).then(() => {
					if (linkType) {
						window.location.href = getLink(linkType);
					}
				})
			)
			.catch(() =>
				console.error('SENTRY DISPATCH: Failed to push funnel logs')
			);
	};

	return (
		<>
			<Helmet>
				<title>Apply for a job on WorkClass.co!</title>
				<meta
					name="description"
					content="Apply for job on WorkClass.co, use our mobile apps, telegram or our website to apply."
				/>
			</Helmet>

			<Row justify="center" style={s.bg}>
				<Col span={22} xl={16} xxl={14}>
					<div style={s.container}>
						<div style={{ width: '100%' }}>
							<Row justify="center">
								<Col span={24} md={16}>
									<Header />
								</Col>
							</Row>

							<Row justify="center" style={s.section}>
								<Col span={24} md={10}>
									<TelegramLink logHandler={handleLog} />
								</Col>
							</Row>

							<Row justify="center" style={s.section}>
								<Col span={24} md={10}>
									<AppsLink logHandler={handleLog} />
								</Col>
							</Row>

							<Row justify="center" style={s.section}>
								<Col span={24} md={10}>
									<a
										onClick={() =>
											handleLog('Clicked workclass.co link', 'website')
										}>
										<p style={{ textAlign: 'center' }}>
											Register on WorkClass.co
										</p>
									</a>
								</Col>
							</Row>
						</div>
					</div>
				</Col>
			</Row>
		</>
	);
});

const s: Stylesheet = {
	bg: {
		backgroundColor: 'var(--primaryPurple)'
	},
	container: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		minHeight: '100vh',
		flexDirection: 'column',
		width: '100%'
	},
	section: {
		marginTop: 10
	}
};

export default IndexPage;

import * as React from 'react';
import { Row, Typography, Grid } from 'antd';
import Logo from '../static/images/company/workclass-logo.svg';

const { Title } = Typography;
const { useBreakpoint } = Grid;

const { memo } = React;

const Header: React.FC = memo(() => {
	const breakpoint = useBreakpoint();
	const isMobile = (breakpoint.xs || breakpoint.sm) && !breakpoint.md;

	return (
		<>
			<Row justify="center">
				<a href="https://workclass.co" style={s.imgA}>
					<img src={Logo} style={s.img} width={200} height={50} />
				</a>
			</Row>
			<Title style={{ ...s.text, ...s.header, fontSize: isMobile ? 40 : 70 }}>
				Apply Now!
			</Title>
			<Title style={{ ...s.text, ...s.subtitle }} level={2}>
				Get a job in 24 hours.
			</Title>
		</>
	);
});

const s: Stylesheet = {
	imgA: {
		textAlign: 'center'
	},
	img: {
		width: '100%',
		marginBottom: 20,
		maxWidth: 200,
		height: '100%'
	},
	text: {
		textAlign: 'center',
		margin: 0,
		padding: 0
	},
	header: {
		fontWeight: 'bold',
		marginBottom: 10
	},
	subtitle: {
		marginBottom: 20
	}
};

export default Header;

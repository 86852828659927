import { post } from './main.api';

export async function pushFunnelLog(
	action: string,
	country: string,
	ip: string,
	system: string,
	urlParams: string
) {
	const request: IRequest = {
		endpoint: `funnel/log`,
		data: { action, country, ip, system, urlParams }
	};

	const response = await post(request);
	return response;
}

import * as React from 'react';
import TelegramIcon from '../static/images/apply/Telegram.svg';

const { memo } = React;

const TelegramLink: React.FC<ILinkComponent> = memo(props => {
	const { logHandler } = props;

	return (
		<a onClick={() => logHandler('Clicked telegram link', 'telegram')}>
			<div style={s.container}>
				<img
					alt="Message with our bot now"
					src={TelegramIcon}
					style={s.img}
					width={60}
					height={60}
				/>
				<span style={s.text}>Telegram</span>
			</div>
		</a>
	);
});

const s: Stylesheet = {
	container: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%',
		width: '100%',
		borderRadius: 15,
		padding: 10,
		boxShadow: 'rgba(17, 12, 46, 0.15) 0px 48px 100px 0px',
		backgroundColor: '#fff'
	},
	img: {
		height: 60
	},
	text: {
		fontSize: 32,
		marginLeft: 20
	}
};

export default TelegramLink;
